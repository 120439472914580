.About {
  overflow: hidden;
  background-image: url("../images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-family: "Montserrat", sans-serif;
  padding-top: 26vh;
  min-height: 100vh;
}

.About h1 {
  font-family: "Great Vibes", cursive;
  font-size: 96px;
}

.About .wrapper {
  background-color: rgba(255, 255, 255, 0.85);
  max-width: 90%;
  font-size: 18px;
}

.About .p-wrapper {
  border: 1px solid #fff;
}

.About .b-shadow {
  box-shadow: 0px 5px 7px rgb(0, 0, 0, 0.3);
}

.About p {
  text-align: justify;
}

@media (max-width: 576px) {
  .About h1 {
    font-size: 54px;
  }
  .About h2,
  h3 {
    font-size: 24px;
  }

  .About p {
    text-align: left;
  }
}
