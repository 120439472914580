.Home {
  overflow: hidden;
  background-image: url("../images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-top: 30vh;
  font-family: "Montserrat", sans-serif;
}

.Home h1 {
  font-family: "Great Vibes", cursive;
  font-size: 72px;
}

.Home h2,
h3 {
  font-size: 32px;
}

.Home a {
  color: #000;
  text-decoration: none;
}

.Home .buttons {
  padding-bottom: 30vh;
}

.Home .primary-btn,
.secondary-btn {
  font-size: 24px;
  padding: 15px 25px;
  border: 1px solid #000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  transition: box-shadow 300ms ease-in-out;
}

.Home .primary-btn {
  background: #b29d8c;
}

.Home .secondary-btn {
  background: #dcdbda;
}

.Home .link {
  font-size: 24px;
}

.Home .primary-btn:hover,
.secondary-btn:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
}

.Home .space {
  background-color: rgba(255, 255, 255, 0.85);
}

.Home .between-parts {
  max-width: fit-content;
  font-size: 24px;
  margin: 0 auto;
}

.Home .project {
  background-color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
}

.Home .project-inf {
  color: #000;
  font-size: 18px;
  line-height: 1;
}

.Home .project:hover .image-transition {
  box-shadow: 0px 10px 15px rgb(0, 0, 0, 0.8);
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}

.Home .project:not(hover) .image-transition {
  transition: all 0.5s ease-in-out;
}

.Home .b-shadow {
  box-shadow: 0px 5px 7px rgb(0, 0, 0, 0.3);
}

@media (max-width: 576px) {
  .Home h1 {
    font-size: 54px;
  }
  .Home h2,
  h3 {
    font-size: 24px;
  }
  .Home .link {
    font-size: 18px;
  }
  .Home .between-parts {
    font-size: 18px;
  }
}
