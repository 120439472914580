.Footer {
  background-color: rgba(255, 255, 255, 0.85);
}
.social-icon {
  background-color: #b29d8c;
  color: rgb(0, 0, 0, 0.9);
  font-size: 48px;
  transition: all 100ms ease-in-out;
}

.social-icon:hover {
  background-color: rgb(0, 0, 0, 0.9);
  color: #b29d8c;
}

.social-icon:not(hover) {
  transition: all 100ms ease-in-out;
}

.email {
  color: #000;
  text-decoration: none;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

.animation-link:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  border-bottom: 1px solid #000;
  transition: 0.5s;
}

.animation-link:hover:after {
  width: 100%;
}
