.Contact {
  overflow: hidden;
  background-image: url("../images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-top: 26vh;
  padding-bottom: 20vh;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.Contact .wrapper {
  background-color: rgba(255, 255, 255, 0.85);
  max-width: 90%;
  font-size: 18px;
}

.Contact h1 {
  font-family: "Great Vibes", cursive;
  font-size: 72px;
}

.Contact .primary-btn {
  background: #b29d8c;
  font-size: 24px;
  padding: 15px 25px;
  border: 1px solid #000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  transition: box-shadow 300ms ease-in-out;
}

.Contact .primary-btn:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
}

.Contact .contact-me-header {
  font-size: 48px;
  line-height: 1;
  justify-content: start;
}
.Contact .contact-me-sub {
  font-size: 24px;
  justify-content: start;
}
.Contact .contact-me-btn {
  justify-content: end;
}

.Contact .b-shadow {
  box-shadow: 0px 5px 7px rgb(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .Contact .contact-me-header,
  .Contact .contact-me-sub,
  .Contact .primary-btn {
    justify-content: center;
    text-align: center;
  }
  .Contact .primary-btn {
    margin: 0 auto;
  }
  .contact-me-btn {
    margin-top: 2em;
  }
}
@media (max-width: 576px) {
  .Contact h1 {
    font-size: 54px;
  }
  .Contact .contact-me-sub,
  .Contact .primary-btn,
  .Contact .email {
    font-size: 18px;
  }
  .Contact .contact-me-header {
    font-size: 36px;
  }
}
